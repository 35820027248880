import { AxiosInstance, AxiosResponse } from "axios";
import { plainToInstance } from "class-transformer";
import { ResponseFleetVesselModel } from "@/models/response.models/response.fleet.vessel.model";
import { VesselFullModel } from "@/models/models/vessel.full.model";
import httpClient from "../utilities/helpers/http.client.axios";
import { VesselAdditionalInfoModel } from "@/models/models/vessel.additional.info.model";
import { excelFileHelper } from "@/utilities/helpers/excel.download.helper";
import { RequestVesselBulkModel } from "@/models/request.models/request.vessel.bulk.model";
import RequestAliasModel from "@/models/request.models/request.alias.model";
import RequestUpdateAliasModel from "@/models/request.models/request.update.alias.model";
import ResponseVesselMultiMediaModel from "@/models/response.models/response.vessel.multi.media.model";
import ApplicationModel from "@/models/models/application.model";
import ResponseCustomerSegmentModel from "@/models/response.models/response.customer.segment.model";
import { ResponseSyncHistoryModel } from "@/models/response.models/response.sync.history.model";
import SlimVesselModel from "@/models/models/slim.vessel.model";
import Query from "@/models/models/query";

export class VesselManager {
    private readonly api: AxiosInstance = httpClient;

    public async getFleet(query: Query): Promise<ResponseFleetVesselModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseFleetVesselModel[]> = await this.api.post("/Vessel/GetFleetVessels", {
                ...query,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            });
            return plainToInstance(ResponseFleetVesselModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async getVesselFullInfo(id: number): Promise<VesselFullModel | Error> {
        try {
            const response: AxiosResponse<VesselFullModel> = await this.api.get(`/Vessel/GetVesselFullInfo/${id}`);
            const transformed = plainToInstance(VesselFullModel, response.data);
            transformed.vesselAdditionalInfo = plainToInstance(VesselAdditionalInfoModel, transformed.vesselAdditionalInfo);
            return transformed;
        } catch (error) {
            return error as Error;
        }
    }

    public async getVesselFullInfoByServiceIdQrCode(code: string): Promise<VesselFullModel | Error> {
        try {
            const response: AxiosResponse<VesselFullModel> = await this.api.get(`/Vessel/GetVesselByServiceIdQrCode/${code}`);
            const transformed = plainToInstance(VesselFullModel, response.data);
            transformed.vesselAdditionalInfo = plainToInstance(VesselAdditionalInfoModel, transformed.vesselAdditionalInfo);
            return transformed;
        } catch (error) {
            return error as Error;
        }
    }

    public async saveVessel(vessel: VesselFullModel): Promise<number | Error> {
        try {
            const response: AxiosResponse<number> = await this.api.post(`/Vessel/SaveVessel`, vessel);

            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async bulkUpdateVessels(model: RequestVesselBulkModel): Promise<void | Error> {
        try {
            const response: AxiosResponse<void> = await this.api.post(`/Vessel/BulkSaveVessel`, model);

            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async exportVessels(selectedIds: number[]): Promise<void | Error> {
        try {
            const response = await this.api.post(`/Vessel/ExportVessels`, selectedIds, { responseType: "blob" });

            excelFileHelper.download(response, "ExportedVessels.xlsx");

            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async deleteVessel(id: number) {
        try {
            const response = await this.api.delete(`/Vessel/DeleteVessel/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getAliases(id: number) {
        try {
            const response = await this.api.get(`/Vessel/GetAliases/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async saveAliases(requests: RequestAliasModel[]) {
        try {
            const response: AxiosResponse<boolean> = await this.api.post(`/Vessel/SaveAliases`, requests);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async updateAliases(requests: RequestUpdateAliasModel[]) {
        try {
            const response: AxiosResponse<boolean> = await this.api.post(`/Vessel/UpdateAliases`, requests);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async deleteAliases(requests: number[]) {
        try {
            const response: AxiosResponse<boolean> = await this.api.post(`/Vessel/DeleteAliases`, requests);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async createMultiMediaAsync(requestAttachment: FormData): Promise<any | Error> {
        try {
            const response = await this.api.post("/Vessel/CreateMultiMedia", requestAttachment);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getMultiMediaAsync(vesselId: number): Promise<ResponseVesselMultiMediaModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseVesselMultiMediaModel[]> = await this.api.get(`/Vessel/GetMultiMedia/${vesselId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getQrCodeParamsAsync(vesselId: number): Promise<string | Error> {
        try {
            const response = await this.api.get(`/Vessel/GetQrCodeParams/${vesselId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getMultiMediaContentAsync(attachmentId: number): Promise<string | Error> {
        try {
            const response = await this.api.get(`/Vessel/GetMultiMediaContent/${attachmentId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async deleteMultiMediaAsync(id: number): Promise<boolean | Error> {
        try {
            const response = await this.api.delete(`/Vessel/DeleteMultiMedia/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async updateMultiMediaMarqueeAsync(id: number): Promise<ResponseVesselMultiMediaModel | Error> {
        try {
            const response = await this.api.put(`/Vessel/UpdateMultiMediaMarquee/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async allCustomerSegmentsAsync(): Promise<ResponseCustomerSegmentModel[] | Error> {
        try {
            const response = await this.api.get(`/Vessel/AllCustomerSegments/`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async allApplicationsAsync(): Promise<ApplicationModel[] | Error> {
        try {
            const response = await this.api.get(`/Vessel/AllApplications/`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async isVesselNumberAvailableAsync(vesselNumber: string): Promise<boolean | Error> {
        try {
            const response = await this.api.get(`/Vessel/IsVesselNumberAvailable/${vesselNumber}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async synchronizeAsync(): Promise<unknown | Error> {
        try {
            const response = await this.api.post(`/Vessel/Synchronize/`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getLatestSynchronizationAsync(): Promise<ResponseSyncHistoryModel | Error> {
        try {
            const response: AxiosResponse<ResponseSyncHistoryModel> = await this.api.get("/Vessel/LatestSynchronization");
            return plainToInstance(ResponseSyncHistoryModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async getSlimVesselsAsync(): Promise<SlimVesselModel[] | Error> {
        try {
            const response: AxiosResponse<SlimVesselModel[]> = await this.api.get("/Vessel/SlimVessels");
            return plainToInstance(SlimVesselModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async synchronizeNewVesselsAsync(): Promise<void | Error> {
        try {
            const response = await this.api.post(`/Vessel/SynchronizeNewVessels/`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    public async getAgentFleetAsync(agentId: number): Promise<ResponseFleetVesselModel[] | Error> {
        try {
            const response: AxiosResponse<ResponseFleetVesselModel[]> = await this.api.get(`/Vessel/Fleet/${agentId}`);
            return plainToInstance(ResponseFleetVesselModel, response.data);
        } catch (error) {
            return error as Error;
        }
    }

    public async canAccessAsync(id: number): Promise<boolean | Error> {
        try {
            const response: AxiosResponse<boolean> = await this.api.get(`/Vessel/CanAccess/${id}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }

    
    public async getMarqueeImageAsync(vesselId: number): Promise<ResponseVesselMultiMediaModel | Error> {
        try {
            const response: AxiosResponse<ResponseVesselMultiMediaModel> = await this.api.get(`/Vessel/GetMarqueeImage/${vesselId}`);
            return response.data;
        } catch (error) {
            return error as Error;
        }
    }
}
export const vesselManager = new VesselManager();
